<template>
  <div>
    <div class="pititle">
      <el-form>
        <div class="litop">
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">

                <el-form-item label="项目名称">
                  <el-input
                      v-model="search_form.name"
                      placeholder="请输入项目名称"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="建设单位">
                  <el-input
                      v-model="search_form.build_company"
                      placeholder="请输入建设单位"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="项目城市">
                  <el-input
                      v-model="search_form.city"
                      placeholder="请输入您的项目城市"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="项目状态">
                  <el-select v-model="status" clearable placeholder="项目状态">
                    <el-option v-for="item in status_list" :key="item.name" :label="item.name"
                               :value="item.id"></el-option>

                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple-light">
                <el-button  type="primary" @click="search">查询</el-button>
                <span >共{{ totalCount }}条</span>
<!--                <el-button style="float: right" type="warning" @click="setMothod">寄送</el-button>-->
              </div>
            </el-col
            >
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="piconent">
      <!-- 表格 -->
      <div class="pitable">
        <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              :selectable="isCanSelected"
              width="55" />
          <el-table-column
              align="center"
              label="报告编号"
              prop="number"
              width="180">
          </el-table-column>
          <el-table-column
              align="center"
              label="项目名称"
              prop="name"

              width="200">
              <template  slot-scope="{ row }">
                <div @click="gotoDetail(row)" class="detailPush">{{row.name}}</div>
              </template>
          </el-table-column>
          <el-table-column align="center" label="建设单位" prop="build_company">
          </el-table-column>

          <el-table-column
              align="center"
              label="项目城市"
              prop="city"
              width="180">
          </el-table-column>
          <el-table-column
              align="center"
              label="建筑类型"
              width="180">
            <template slot-scope="{ row }">
              <span v-if="row.building_type==1">公共建筑</span>
              <span v-if="row.building_type==2">居住建筑</span>
            </template>
          </el-table-column>

          <el-table-column
              align="center"
              label="测评星级"
              prop="stars"
              width="180">
            <template slot-scope="{ row }">
              <i v-for="item in row.stars" :key="item" class="el-icon-star-on"></i>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="标识阶段"
              width="180">
            <template slot-scope="{ row }">
              <span v-if="row.stage==1">能效评估</span>
              <span v-else-if="row.stage==2">实测评估</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="申报时间"
          >
            <template slot-scope="{row}">
              <span @click="outerVisible = true">{{ row.created_at | formatDate('yyyy-MM-dd') }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="项目状态"
              width="180">
            <template slot-scope="{ row }">
              <div class="detailPush" @click="gotoDetail(row)">
                <span v-if="row.status == 1">未提交</span>
                <span v-else-if="row.status == 2">形式初审</span>
                <span v-else-if="row.status == 3">形式复审</span>
                <span v-else-if="row.status == 4">审查分配</span>
                <span v-else-if="row.status == 5">专家初审</span>
                <span v-else-if="row.status == 6">专家复审</span>
                <span v-else-if="row.status == 7">专家复审</span>
                <span v-else-if="row.status == 8">审核通过</span>
                <span v-else-if="row.status == 9">待公示</span>
                <span v-else-if="row.status == 10">公示中</span>
                <span v-else-if="row.status == 11">公告中</span>
                <span v-else-if="row.status == 12">待寄送</span>
                <span v-else-if="row.status == 13">待自取</span>
                <span v-else-if="row.status == 14">已完成</span>
                <span v-else-if="row.status == 15" style="color:#e6a23c">形式审查待修改</span>
                <span v-else-if="row.status == 16">形式审查已修改</span>
                <span v-else-if="row.status == 17" style="color:#e6a23c">技术审查待修改</span>
                <span v-else-if="row.status == 18">技术审查已修改</span>
                <span v-else-if="row.status == 19">专家待复审</span>
                <span v-else-if="row.status == 22" >标识制作中</span>
                <span v-else-if="row.status == 23" @click.stop="setMothod(row)" style="color:#e6a23c">待寄取</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pageTotal">
        <div class="block">
          <el-pagination
              :current-page="page"
              :page-count="totalPage"
              :page-size="limit"
              :total="totalCount"
              background
              layout=" prev, pager, next, total, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="outerVisible" title="选择自取或寄送?" width="30%">
      <el-dialog
          :visible.sync="innerVisible"
          append-to-body
          title="寄送地址"
          width="30%">
        <div class="srdz">
          <el-form ref="form" :rules="rule" :model="address_form" label-width="100px">
            <el-form-item label="收件人">
              <el-input v-model="address_form.name"></el-input>
            </el-form-item>
            <el-form-item label="收件地址">
              <el-input v-model="address_form.send_address"></el-input>
            </el-form-item>
            <el-form-item prop="tel" label="联系方式">
              <el-input v-model="address_form.tel"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="set_address" type="primary">保存</el-button>
        </div>
      </el-dialog>
      <el-dialog
          :visible.sync="toVisible"
          append-to-body
          title="自取地址"
          width="30%">
        <div class="srdz">
          南京市鼓楼区江东北路287号银城广场B座四楼
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="toVisible=false,outerVisible=false" type="primary">知道了</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="changeQu(2)">自取</el-button>
        <el-button type="primary" @click="changeQu(1)">寄送</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { changeQuApi, changeStatus, delProject, getProjectList, setAddress} from "../../../../api/project";

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == '' || fmt == null) {
        fmt = 'yyyy-MM-dd'
      }
      if (!timestamp) {
        return ''
      }
      let date = new Date(timestamp)
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear())
      }
      // 第二步匹配其他，然后替换
      let obj = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (let key in obj) {
        let pat = `(${key})`
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + ''
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ('00' + str).substr(-2))
        }
      }
      return fmt
    }
  },
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      rule: {
        tel:  { required: true, validator: validatorPhone, trigger: 'blur' },
      },
      outerVisible: false,
      toVisible: false,
      innerVisible: false,
      form: '',
      search_form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      checked_list: [],
      status: '',
      status_list: [
        {
          id: 2,
          name: '形式初审'
        },
        {
          id: 5,
          name: '专家初审'
        },
        {
          id: 9,
          name: '待公示'
        },
        {
          id: 10,
          name: '公示中'
        },
        {
          id: 11,
          name: '公告中'
        },
        {
          id: 12,
          name: '待寄送'
        },
        {
          id: 13,
          name: '待自取'
        },
        {
          id: 16,
          name: '已修改'
        },
      ],


      //寄送地址
      address_form: {},
      click_project_id: '',
    };
  },
  mounted() {
    this.getList();
  },
  methods: {

    //改变项目状态  status：12 待寄送 13 待自取
    changeProjectStatus(status){
      // let ids = this.checked_list.map(item => item.id);
      let params = {
        ids: [this.click_project_id],
        status: status,
        token: this.$store.state.token,
      }
      changeStatus(params).then((res) => {
        console.log(res);
        this.$message.success("选择寄取方式成功");
        this.getList();
      });
    },

    changeQu(qu){
      let params = {
        token: this.$store.state.token,
        id: this.click_project_id,
        qu: qu
      }
      //感觉这个 changeQuApi接口没啥用
      changeQuApi(params).then(res =>{
        console.log(res);
        if (qu==2){
          this.toVisible = true;
          this.changeProjectStatus(13)
        }else {
          this.innerVisible = true
        }
      })

 /*     if (qu==2){
        this.toVisible = true;
        this.changeProjectStatus(13)
      }else {
        this.innerVisible = true
      }*/

    },

    //设置寄送地址
    set_address(){
      // let ids = this.checked_list.map(item => item.id);
      this.$refs.form.validate(vali => {
        if (vali){
          let params = this.address_form;
          params.token = this.$store.state.token;
          params.project_id = this.click_project_id;
          // params.project_id = ids;
          let that = this;
          setAddress(params).then(res => {
            console.log(res);
            that.$message.success('设置成功');
            that.changeProjectStatus(12)
            that.innerVisible = false;
            that.outerVisible = false;
            that.address_form = {};
          })
        }
      })

    },

    gotoDetail(row) {
      this.$router.push({path: 'ipImformation?id=' + row.id})
    },

      setMothod(row){
      console.log(row,"==================")
      this.outerVisible = true;
      this.click_project_id=row.id
    },
    isCanSelected(row){
      return row.status == 23
    },
    handleSelectionChange(val) {
      this.checked_list = val;
    },

    //删除数据
    check_del() {
      let that = this;
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = this.checked_list.map(item => {
          return item.id;
        })
        // console.log(ids);
        let params = {
          token: this.$store.state.token,
          id: ids
        }
        delProject(params).then(res => {
          console.log(res);
          that.getList();
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //获得表格数据
    getList() {
      let that = this;
      let params = this.search_form;
      params.company = 1;
      params.page = this.page;
      params.limit = this.limit;
      params.token = this.$store.state.token;
      if (this.status && this.status != '') {
        params.status = this.status;
      } else {
        params.status = '2,3,5,6,7,8,9,10,11,12,13,14,15,17,19,22,23';
      }
      getProjectList(params).then(res => {
        // console.log(res);
        that.tableData = res.data;
        that.page = res.page;
        that.totalCount = res.totalCount;
        that.totalPage = res.totalPage;
      }).catch(err => {
        console.log(err);
      })
    },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },

    // 分页
    handleSizeChange(page) {
      this.limit = page
      this.getList()
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page
      this.getList()
    },
  }
};
</script>
<style scoped>
/deep/ .el-form-item__label {
  font-size: 18px;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 255px;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #3086fb;
  font-weight: bold;
  font-size: 18px;
}

.detailPush {
  color: #3086fb;
  text-decoration: none;
  cursor: pointer;
}

.pitable {
  height: 60vh;
}

.litop {
  margin: 20px 24px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.pageTotal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 38px 0;
}
</style>
